export const App = {
    mobile: false,
    H: document.getElementsByTagName('html')[0],
    colorAccent: '#18f794',
    colorBlack: '#111111',
    breakpoints: {
        xs: 359,
        s: 767,
        m: 999,
        l: 1299,
        xl: 2000
    }
};

if ('ontouchstart' in window) {
    App.mobile = true;
    App.H.classList.add('mobile');
} else {
    App.H.classList.add('desktop');
}
