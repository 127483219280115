/**
 * Задаём правильную высоту 100vh на мобильных
 * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

 *   .top {
 *       min-height: 100vh;
 *       min-height: calc(var(--vh, 1vh) * 100);
 *   }
*/

import { App } from './App';

function updateVH() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

if (App.mobile) {
    let tmt;

    updateVH();

    window.addEventListener('orientationchange', () => {
        /**
         * Используем таймаут, иначе вычисление происходит
         * до того, как экран развернулся
         */
        clearTimeout(tmt);

        tmt = setTimeout(() => {
            updateVH();
        }, 2000);
    });
}
