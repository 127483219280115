import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { App } from './App';
import { mouse } from './Mouse';
import { hoverCoord } from './CanvasHoverCoord';
import * as dat from 'dat.gui';
import dotsVertexShader from '../shaders/dots/vertex.glsl';
import dotsFragmentShader from '../shaders/dots/fragment.glsl';


const gui = new dat.GUI();
gui.hide();


let allowShadows = App.mobile !== true;



/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
};
let scale = 0.75;



/**
 * Loaders
 */
const loadingManager = new THREE.LoadingManager(
    // Loaded
    () => {
        App.H.classList.remove('_is-loading');
        App.H.classList.add('_is-loaded');
    }
);
const textureLoader = new THREE.TextureLoader(loadingManager);
const textLoader = new THREE.FontLoader(loadingManager);



/**
 * Canvas
 */
// const container = document.querySelector('#hello');
const canvas = document.querySelector('#hello-canvas');



/**
 * Scene
 */
const scene = new THREE.Scene();

const generalGroup = new THREE.Group();
generalGroup.scale.set(scale, scale, scale);

scene.add(generalGroup);



/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
});

renderer.setSize(sizes.width, sizes.width);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
// renderer.shadowMap.enabled = allowShadows;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.physicallyCorrectLights = true;
// renderer.outputEncoding = THREE.sRGBEncoding;



/**
 * Camera
 */
const cameraInitialPosition = [0, 0, 10];

const camera = new THREE.PerspectiveCamera(40, 1, 0.1, 100);
camera.position.set(...cameraInitialPosition);
scene.add(camera);

// gui.add(camera.position, 'x').min(0).max(7).step(0.01);
// gui.add(camera.position, 'y').min(0).max(20).step(0.01);
// gui.add(camera.position, 'z').min(0).max(20).step(0.01);


/**
 * Lights
 */

// Ambient
const ambientLight = new THREE.AmbientLight(0xffffff, 3.2);
scene.add(ambientLight);
gui.add(ambientLight, 'intensity').min(0).max(10).step(0.01).name('amb INT');

// Directional
const directionalLight = new THREE.DirectionalLight(0xffffff, 2.04);
directionalLight.position.set(-5.7, 15, 20);
// directionalLight.position.set(-2.74, 10, 20);
// directionalLight.castShadow = allowShadows;
directionalLight.shadow.mapSize.width = 1024;
directionalLight.shadow.mapSize.height = 1024;
scene.add(directionalLight);

gui.add(directionalLight.position, 'x').min(-20).max(20).step(0.01).name('dir X');
gui.add(directionalLight.position, 'y').min(-20).max(20).step(0.01).name('dir Y');
gui.add(directionalLight.position, 'z').min(-20).max(20).step(0.01).name('dir Z');
gui.add(directionalLight, 'intensity').min(0).max(10).step(0.01).name('dir INT');



/**
 * Controls
 */
// const controls = new OrbitControls(camera, canvas);
// controls.enableDamping = true;
// controls.enabled = false;



/**
 * Text
 */
const textGroup = new THREE.Group();

textLoader.load('/assets/Poppins_SemiBold.json', (font) => {
    const textOptions = {
        font: font,
        size: 0.5,
        height: 0.2,
        curveSegments: 12,
        bevelEnabled: true,
        bevelThickness: 0.0075,
        bevelSize: 0.0075,
        bevelOffset: 0,
        bevelSegments: 5
    };
    const textSubGroup = new THREE.Group();

    const textHeight = textOptions.size * 5.5;
    const textMaterial = new THREE.MeshStandardMaterial({
        color: App.colorAccent,
        metalness: 0.5,
        roughness: 0.5,
    });

    // gui.add(textMaterial, 'metalness').min(0).max(1).name('metalness');
    // gui.add(textMaterial, 'roughness').min(0).max(1).name('roughness');

    const textGeometry1 = new THREE.TextBufferGeometry('I am a', textOptions);
    const textGeometry2 = new THREE.TextBufferGeometry('creative', textOptions);
    const textGeometry3 = new THREE.TextBufferGeometry('frontend', textOptions);
    const textGeometry4 = new THREE.TextBufferGeometry('developer', textOptions);

    const textMesh1 = new THREE.Mesh(textGeometry1, textMaterial);
    textMesh1.position.y = textHeight / 4 * 3;

    const textMesh2 = new THREE.Mesh(textGeometry2, textMaterial);
    textMesh2.position.y = textHeight / 4 * 2;

    const textMesh3 = new THREE.Mesh(textGeometry3, textMaterial);
    textMesh3.position.y = textHeight / 4 * 1;

    const textMesh4 = new THREE.Mesh(textGeometry4, textMaterial);
    textMesh4.castShadow = allowShadows;

    // Change the pivot point to the center of the group
    textSubGroup.add(textMesh1, textMesh2, textMesh3, textMesh4);
    textSubGroup.position.x = -1.7;
    textSubGroup.position.y = -1;

    textGroup.add(textSubGroup);
    textGroup.position.x = -2 + 1.7;
    textGroup.position.y = textHeight * -0.46 + 1;
    textGroup.position.z = -0.2;

    generalGroup.add(textGroup);
});



/**
 * Plane
 */
const dotAlphaTexture = textureLoader.load('/assets/amap.png');
const planeGeometry = new THREE.PlaneBufferGeometry(20, 2, 150, 15);

const planePointsGeometry = new THREE.BufferGeometry();
planePointsGeometry.setAttribute(
    'position',
    new THREE.Float32BufferAttribute(planeGeometry.attributes.position.array, 3)
);


// Material
const planeShaderMaterial = new THREE.ShaderMaterial({
    uniforms: {
        uDefaultColor: { value: new THREE.Color(0xffffff) },
        uHoverColor: { value: new THREE.Color(App.colorAccent) },
        uTime: { value: 0 },
        uHoverCoord: { value: new THREE.Vector2(hoverCoord.x, hoverCoord.y) },
        uFrequency: { value: new THREE.Vector2(10, 5) },
        uAlphaTexture: { value: dotAlphaTexture },
        uPointSize: { value: getPointSize() * renderer.getPixelRatio() },
        uRenderWidth: { value: renderer.getContext().drawingBufferWidth }
    },
    vertexShader: dotsVertexShader,
    fragmentShader: dotsFragmentShader,
    transparent: true
});

const plane = new THREE.Points(planePointsGeometry, planeShaderMaterial);
plane.position.z = -1;

generalGroup.add(plane);

// Get point size
function getPointSize() {
    // desktop
    let size = 5;

    // horizontal tablet
    if (sizes.width <= App.breakpoints.l && sizes.width > App.breakpoints.m) {
        size = 3.5;
    }

    // Vertical tablet
    if (sizes.width <= App.breakpoints.m && sizes.width > App.breakpoints.s) {
        size = 3.5;
    }

    // iPhone 6
    if (sizes.width <= App.breakpoints.s && sizes.width > App.breakpoints.xs) {
        size = 2.5;
    }

    // iPhone 5s
    if (sizes.width <= App.breakpoints.xs) {
        size = 2;
    }

    // 4k monitor
    if (sizes.width > App.breakpoints.xl) {
        size = sizes.width / 2000 * 4.5;
    }

    return size;
}



/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
    const elapsedTime = clock.getElapsedTime();

    // Materials
    planeShaderMaterial.uniforms.uTime.value = elapsedTime;
    planeShaderMaterial.uniforms.uHoverCoord.value.x = hoverCoord.x;
    planeShaderMaterial.uniforms.uHoverCoord.value.y = hoverCoord.y;

    // Update controls
    // controls.update();

    // Text
    textGroup.rotation.y = mouse.x * 0.2;
    textGroup.rotation.x = mouse.y * -0.2;

    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
}

tick();



/**
 * Resize
 */
window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update scale
    updateTextScale();

    // Update camera
    camera.aspect = 1;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.width);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    // Update materials
    planeShaderMaterial.uniforms.uPointSize.value = getPointSize() * renderer.getPixelRatio();
    planeShaderMaterial.uniforms.uRenderWidth.value = renderer.getContext().drawingBufferWidth;
});



function updateTextScale() {
    let scaleDelta = 0.6;
    let transformDelta = -0.43; // -0.43, 0.05

    // Horizontal tablet
    if (sizes.width <= App.breakpoints.l && sizes.width > App.breakpoints.m) {
        scaleDelta = 0.45;
        transformDelta = -0.55;
    }

    // Vertical tablet
    if (sizes.width <= App.breakpoints.m && sizes.width > App.breakpoints.s) {
        scaleDelta = 0.45;

        if (sizes.width < sizes.height) {
            scaleDelta = 0.5;
        }

        transformDelta = sizes.width / scaleDelta * -0.0015 + 2.32; // 1.92
    }

    // iPhone 6
    if (sizes.width <= App.breakpoints.s && sizes.width > App.breakpoints.xs) {
        scaleDelta = 0.30;
        transformDelta = sizes.width / scaleDelta * -0.0015 + 1.92;
    }

    // iPhone 5s
    if (sizes.width <= App.breakpoints.xs) {
        scaleDelta = 0.27;
        transformDelta = sizes.width / scaleDelta * -0.0015 + 1.94;
    }

    scale = 2000 / sizes.width * scaleDelta;

    // 4k monitor
    if (sizes.width > App.breakpoints.xl) {
        scaleDelta = 0.85;
        transformDelta = -0.82;
        scale = scale * (sizes.width / 2000) * scaleDelta;
    }

    generalGroup.scale.set(scale, scale, scale);
    generalGroup.position.x = transformDelta * scale;
}

updateTextScale();


/**
 * Helpers
 */
// const axesHelper = new THREE.AxesHelper(1);
// scene.add(axesHelper);
